import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFound>
      <h2>404</h2>
      <span>drink responsibly</span>
    </NotFound>
  </Layout>
)

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 7rem);
  justify-content: center;
  align-items: center;

  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;

  h2 {
    font-size: 8rem;

    @media (min-width: 700px) {
      font-size: 12rem;
    }
  }

  span {
    display: block;
    text-align: center;
    font-size: 2rem;

    @media (min-width: 700px) {
      font-size: 3.2rem;
    }
  }
`

export default NotFoundPage
